import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Heading from 'shopper/components/Heading';
import Text from 'shopper/components/Text';

import Image from 'components/Image';

import { toSubcategoryLink } from 'lib/links';
import placeholder from 'lib/placeholder';

import APP from 'constants/app';

const subcategories = [
  {
    subcategorySlug: 'tv',
    subcategoryName: 'Smart TV',
    image: `${APP.LOCAL_IMAGE_PATH}/features/sidebar/relevant-subcategories/smart-tv.png`,
  },
  {
    subcategorySlug: 'android',
    subcategoryName: 'Android',
    image: `${APP.LOCAL_IMAGE_PATH}/features/sidebar/relevant-subcategories/android.png`,
  },
  {
    subcategorySlug: 'iphone',
    subcategoryName: 'iPhone',
    image: `${APP.LOCAL_IMAGE_PATH}/features/sidebar/relevant-subcategories/iphone.png`,
  },
  {
    subcategorySlug: 'fraldas',
    subcategoryName: 'Fralda',
    image: `${APP.LOCAL_IMAGE_PATH}/features/sidebar/relevant-subcategories/fralda.png`,
  },
  {
    subcategorySlug: 'fone-de-ouvido',
    subcategoryName: 'Fone de ouvido',
    image: `${APP.LOCAL_IMAGE_PATH}/features/sidebar/relevant-subcategories/fone-de-ouvido.png`,
  },
];

const RelevantSubcategories = forwardRef(({ className, ...rest }, ref) => (
  <Box ref={ref} className={twMerge('p-4', className)} {...rest}>
    <Heading as="span" className="mb-5" size="size6">
      {placeholder('titles.moreOffers')}
    </Heading>
    <div className="space-y-4">
      {subcategories.map(({ image, subcategorySlug, subcategoryName }) => (
        <NextLink
          key={subcategorySlug}
          href={toSubcategoryLink(subcategorySlug)}
          prefetch={false}
          passHref
        >
          <a
            className="flex items-center"
            data-test-selector="relevant-subcategory-item"
          >
            <Image
              alt={`Imagem de um ${subcategoryName}`}
              className="object-cover"
              height={64}
              src={image}
              width={64}
            />
            <Text className="ml-4" bold>
              {subcategoryName}
            </Text>
          </a>
        </NextLink>
      ))}
    </div>
  </Box>
));

RelevantSubcategories.displayName = 'RelevantSubcategories';

RelevantSubcategories.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default memo(RelevantSubcategories);
